<template>
    <div>
        <el-card class="box-card" shadow="never">
            <el-row :gutter="20" v-if="!$route.params.id">
                <el-col :sm="24" :md="14" :lg="12">
                    <el-form-item label="Search User (Employee/Doctor)" prop="patient_id">
                        <el-input v-model="filter.phone_number" placeholder="Enter Phone no"/>
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="4" :lg="4">
                    <el-button type="success" round @click="searchUser()">Search</el-button>
                </el-col>
            </el-row>

        
            <el-form
                :model="form"
                label-position="top"
                label-width="100px"
                v-if="Object.keys(user).length !== 0"
                ref="installment">

                <h4> <b>Doctor/Employee's Information : </b></h4>
                <el-row :gutter="20">
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label=" Name">
                            <span>{{ user.fullname }}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label="Phone">
                            <span>{{ user.phone_number }}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label="User Type">
                            <span>{{ user.user_type }}</span>
                        </el-form-item>
                    </el-col>
                </el-row>

                <h4> <b>Disbursements : </b></h4>
                <el-row>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label="Payment Method">
                            <el-select v-model="form.payment_method" clearable class="m-2 w-100">
                                <el-option value="" label="Select One"/>
                                <el-option value="Bank" label="Bank"/>
                                <el-option value="Bkash" label="Bkash"/>
                                <el-option value="Nagad" label="Nagad"/>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :xs="24" :sm="24" :md="16" :lg="16">
                        <table id="referral-tbl">
                            <thead>
                                <tr>
                                    <th style="width: 115px; text-align: center">
                                        Select/Unselect <br/>
                                        <input type="checkbox" @change="selectAll"/>
                                    </th>
                                    <th style="text-align: center">Referral Amount</th>
                                    <th style="text-align: center">Created At</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="referral in referrals" :key="referral.id" :class="referral.checked?'checked':''">
                                    <td style="width: 115px; text-align: center">
                                        <input type="checkbox" :value="true" v-model="referral.checked"/>
                                    </td>
                                    <td style="text-align: center">{{ referral.referral_amount }}</td>
                                    <td style="text-align: center">{{ referral.created_at }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="8" :lg="8">
                        <h3>Total Selected: {{ totalSelected }}</h3>
                        <h3>Total Amount: {{ totalAmount }} Tk.</h3>
                    </el-col>
                </el-row>


                <br/>
                <div class="text-center">
                    <el-button type="primary" @click="submitForm()">
                    Confirm
                </el-button>
            </div>
            </el-form>
        
        </el-card>
    </div>
</template>
<script>
import axios from 'axios';
    export default {
        name: 'DisbursementCreate',
        title: 'Create Disbursement',
        data() {
            return {
                user: {},
                is_form: false,
                referrals:[],
                form: {
                    user_id: null,
                    referralIds: [],
                    payment_method: null
                },
                filter: {
                    phone_number:'',
                    start_date:'',
                    end_date:''
                }
            }
        },
        computed: {
            totalSelected() {
                let count = 0;
                this.referrals.forEach((item)=> {
                    if(item.checked) {
                        count++;
                    }
                })
                return count;
            },
            totalAmount() {
                let count = 0;
                this.referrals.forEach((item)=> {
                    if(item.checked) {
                        count += parseFloat(item.referral_amount);
                    }
                })
                return count;
            }
        },
        created() {
      
        },
        methods: {
            searchUser() {
                axios.get(`/api/v2/referrals/bonus/by-user?phone_number=${this.filter.phone_number}`)
                .then(res => {
                   this.referrals = res.data.data;
                   this.user = res.data.user;
                })
            },
            selectAll(event) {
                this.referrals.map((item) => {
                    item.checked = event.target.checked   
                })
            },
            
            submitForm() {
                this.form.referralIds = [];
                let url = '';
                if(this.$route.params.id) {
                    // url = `/api/v2/welfare/applications/${this.application_details.id}?_method=put`;
                } else {
                    url = `/api/v2/referrals/disbursements`
                }

                this.referrals.forEach((item)=> {
                    if(item.checked) {
                        this.form.referralIds.push(item.id);
                    }
                })
                this.form.user_id = this.user.id;

                axios
                .post(url, this.form)
                .then(res => {
                   
                    if (res.data.status_code != 200) {
                        this.$notify({
                        title: 'Failed',
                        message: res.data.message,
                        type: 'error',
                        duration: 2000,
                        });
                    } else {
                            this.$router.push('/disbursements');
                            this.$notify({
                            title: 'Success',
                            message: "Successfully disbursement saved!",
                            type: 'success',
                            duration: 2000,
                        });
                    }
                    
                })
            },

        }
    }
</script>
<style lang="scss" scoped>
#referral-tbl {
    border-collapse: collapse;
    width: 100%;
    td, th {
        border: 1px solid #ddd;
        padding: 8px;
    }
    th {
        // padding-top: 12px;
        // padding-bottom: 12px;
        text-align: left;
        background-color: #04AA6D;
        color: white;
    }
    tr {
        // &:nth-child(even){
        //     background-color: #f2f2f2;
        // }    
        &:hover {
            background-color: #ddd;
        }
        &.checked{
            background-color: #ccc;
        }    
    }
    tbody {
        display: block;
        max-height: 250px;
        overflow: auto;
    }
    thead, tbody {
        tr {
            display: table;
            width: 100%;
            table-layout: fixed;/* even columns width , fix width of table too*/
        }
    }
    thead {
        width: calc( 100% - 1em )/* scrollbar is average 1em/16px width, remove it from thead width */
    }
}
</style>